// Import required libraries
require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")
require("alpinejs")
require("jquery")
require("select2")
require("easy-autocomplete")
require("@nathanvda/cocoon")

require('datatables.net-dt')(window, $)
require('datatables.net-buttons-dt')(window, $)
require('datatables.net-buttons/js/buttons.colVis.js')(window, $)
require('datatables.net-buttons/js/buttons.html5.js')(window, $)
require('datatables.net-buttons/js/buttons.print.js')(window, $)
require('datatables.net-responsive-bs')(window, $)
require('datatables.net-select')(window, $)

// Import fcm_notifications.js after all dependencies are loaded
// import { requestNotificationPermission } from "packs/fcm_notifications";

// // Ensure that requestNotificationPermission is called on a specific page or event
// document.addEventListener("DOMContentLoaded", () => {
//   if (Notification.permission !== "granted") {
//     requestNotificationPermission();
//   }
// });

import "packs/people"
import "packs/datatable"
import "./fcm_notifications"; // Ensure fcm_notifications is imported correctly
import '../stylesheets/application.scss'
