document.addEventListener("turbolinks:load", function() {
    const tableElement = document.querySelector('#unpaid-bank-balance-table');
  
    if (tableElement) {
      if ($.fn.DataTable.isDataTable(tableElement)) {
        $(tableElement).DataTable().clear().destroy();
      }
  
      $(tableElement).DataTable({
        processing: true,
        serverSide: true,
        ajax: {
          url: $(tableElement).data('source'),
          data: function(d) {
            d.proceeds_paid_false_or_dealer_incentives_false = true;
          }
        },
        pagingType: "full_numbers",
        columns: [
          { data: "customer_name" },
          { data: "vehicle_cs_no" },
          { data: "financed" },
          { data: "date_of_released" },
          { data: "bank" },
          { data: "proceeds_paid" },
          { data: "email_date" },
          { data: "status" },
          { data: "aging" } // Aging column
        ],
        columnDefs: [
          {
            targets: "_all",
            className: 'px-6 py-4 whitespace-no-wrap border-b text-sm leading-5 text-gray-500'
          }
        ],
        responsive: true,
        "rowCallback": function(row, data) {
          if (data.proceeds_paid === "false") {
            $('td:eq(5)', row).css('background-color', 'rgb(254 226 226 / var(--tw-bg-opacity))');
            $('td:eq(5)', row).css('color', 'rgb(153 27 27 / var(--tw-text-opacity))');
            $('td:eq(5)', row).html('Pending');
          } else if (data.proceeds_paid === "true") {
            $('td:eq(5)', row).css('background-color', 'rgb(220 252 231 / var(--tw-bg-opacity))');
            $('td:eq(5)', row).css('color', 'rgb(22 101 52 / var(--tw-text-opacity))');
            $('td:eq(5)', row).html('Paid');
          }
        },
        dom: 'Bfrtip',
        stateSave: true,
        buttons: [
          { extend: 'colvis', className: 'inline-flex items-center px-4 py-2 border border-gray-300 shadow-sm text-base font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50' },
          'copy',
          'pageLength'
        ]
      });
    }
  });
  
  document.addEventListener("turbolinks:before-cache", function() {
    const tableElement = document.querySelector('#unpaid-bank-balance-table');
    if (tableElement && $.fn.DataTable.isDataTable(tableElement)) {
      $(tableElement).DataTable().clear().destroy();
    }
  });
  

  document.addEventListener("turbolinks:load", function() {
    const tableElement = document.querySelector('#for-approval-table');
  
    if (tableElement) {
      if ($.fn.DataTable.isDataTable(tableElement)) {
        $(tableElement).DataTable().clear().destroy();
      }
  
      $(tableElement).DataTable({
        processing: true,
        serverSide: true,
        ajax: {
          url: $(tableElement).data('source'),
          data: function(d) {
            d.ok_to_invoice_false_or_ok_to_release_false = true;
          }
        },
        pagingType: "full_numbers",
        columns: [
          { data: "customer_name" },
          { data: "vehicle_cs_no" },
          { data: "ok_to_invoice" },
          { data: "ok_to_release" },
          { data: "date_of_release" }
        ],
        columnDefs: [
          {
            targets: "_all",
            className: 'px-6 py-4 whitespace-no-wrap border-b text-sm leading-5 text-gray-500'
          }
        ],
        responsive: true,
        "rowCallback": function(row, data) {
          if (data.ok_to_invoice === "No") {
            $('td:eq(2)', row).css('background-color', 'rgb(254 226 226 / var(--tw-bg-opacity))');
            $('td:eq(2)', row).css('color', 'rgb(153 27 27 / var(--tw-text-opacity))');
          } else if (data.ok_to_invoice === "Yes") {
            $('td:eq(2)', row).css('background-color', 'rgb(220 252 231 / var(--tw-bg-opacity))');
            $('td:eq(2)', row).css('color', 'rgb(22 101 52 / var(--tw-text-opacity))');
          }
  
          if (data.ok_to_release === "No") {
            $('td:eq(3)', row).css('background-color', 'rgb(254 226 226 / var(--tw-bg-opacity))');
            $('td:eq(3)', row).css('color', 'rgb(153 27 27 / var(--tw-text-opacity))');
          } else if (data.ok_to_release === "Yes") {
            $('td:eq(3)', row).css('background-color', 'rgb(220 252 231 / var(--tw-bg-opacity))');
            $('td:eq(3)', row).css('color', 'rgb(22 101 52 / var(--tw-text-opacity))');
          }
        },
        dom: 'Bfrtip',
        stateSave: true,
        buttons: [
          { extend: 'colvis', className: 'inline-flex items-center px-4 py-2 border border-gray-300 shadow-sm text-base font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50' },
          'copy',
          'pageLength'
        ]
      });
    }
  });
  
  // Destroy DataTable before TurboLinks caches the page
  document.addEventListener("turbolinks:before-cache", function() {
    const tableElement = document.querySelector('#for-approval-table');
    if (tableElement && $.fn.DataTable.isDataTable(tableElement)) {
      $(tableElement).DataTable().clear().destroy();
    }
  });
  
