// app/javascript/packs/fcm_notifications.js

// Firebase configuration

if ('serviceWorker' in navigator) {
  // Register the service worker
  navigator.serviceWorker.register('/firebase-messaging-sw.js')
    .then((registration) => {
      console.log('Service Worker registered with scope:', registration.scope);
    })
    .catch((error) => {
      console.error('Service Worker registration failed:', error);
    });
}

const firebaseConfig = {
  apiKey: "AIzaSyB8o4FQyt-wpd2y6XIo4JKY-s4HiAOPzJs",
  authDomain: "byd-vsi-admin.firebaseapp.com",
  projectId: "byd-vsi-admin",
  storageBucket: "byd-vsi-admin.firebasestorage.app",
  messagingSenderId: "581742799337",
  appId: "1:581742799337:web:680f8d3a5c1db0262e4378",
  measurementId: "G-3587W4DDQ2"
};

// Initialize Firebase only if not already initialized
if (!firebase.apps.length) {
  firebase.initializeApp(firebaseConfig);
}

const messaging = firebase.messaging();

// Request permission and get FCM token
// app/javascript/packs/fcm_notifications.js


async function requestNotificationPermission() {
  console.log("Requesting notification permission...");

  try {
    const permission = await Notification.requestPermission();
    console.log("Notification permission result:", permission);

    if (permission === "granted") {
      const currentToken = await messaging.getToken({
        vapidKey: "BG3dINE41Vfn-jUSbgPXaChDi5g9i7Xv-UuoGSxiYS_56ZAS1bJgUkzgZHFxRRI_o__fMU0Qc5Qb64BbDc_U2hQ",
      });

      if (currentToken) {
        console.log("FCM Token:", currentToken);
        saveTokenToServer(currentToken);
      } else {
        console.log("No registration token available.");
      }
    } else {
      console.log("Notification permission denied.");
    }
  } catch (error) {
    console.error("Error requesting permission or retrieving token:", error);
  }
}



// function requestNotificationPermission() {
//   console.log("Requesting notification permission...");

//   Notification.requestPermission()
//     .then(permission => {
//       console.log("Notification permission result:", permission);
//       if (permission === "granted") {
//         messaging
//           .getToken({
//             vapidKey: "BG3dINE41Vfn-jUSbgPXaChDi5g9i7Xv-UuoGSxiYS_56ZAS1bJgUkzgZHFxRRI_o__fMU0Qc5Qb64BbDc_U2hQ",
//           })
//           .then(currentToken => {
//             if (currentToken) {
//               console.log("FCM Token:", currentToken);
//               saveTokenToServer(currentToken);
//             } else {
//               console.log("No registration token available.");
//             }
//           })
//           .catch(err => {
//             console.error("Error retrieving token:", err);
//           });
//       } else {
//         console.log("Notification permission denied.");
//       }
//     })
//     .catch(error => {
//       console.error("Error requesting permission:", error);
//     });
// }



// Save the FCM token to the server
function saveTokenToServer(token) {
  fetch("/notification_tokens", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "X-CSRF-Token": document.querySelector('meta[name="csrf-token"]').content,
    },
    body: JSON.stringify({ notification_token: { token: token, platform: "fcm" } }),
  })
    .then(response => response.json())
    .then(data => console.log("Token saved:", data))
    .catch(error => console.error("Error saving token:", error));
}



messaging.onMessage((payload) => {
  console.log("Foreground message received: ", payload);

  if (Notification.permission === "granted") {
    const notificationTitle = payload.notification.title;
    const notificationOptions = {
      body: payload.notification.body,
      icon: payload.notification.icon || '/byd-pampanga.png', // Default icon
      data: {
        url: payload.data.url // Pass the URL to the notification's data
      },
    };

    // Show the notification using the Notification API
    const notification = new Notification(notificationTitle, notificationOptions);

    // Add click handler to the notification
    notification.onclick = (event) => {
      event.preventDefault(); // Prevent default behavior

      console.log("Notification clicked. Redirecting to:", notificationOptions.data.url);

      // Close the notification
      notification.close();

      // Redirect to the specified URL
      if (notificationOptions.data.url) {
        window.open(notificationOptions.data.url, '_blank'); // Open in a new tab
      }
    };
  }
});


// Export the function to trigger permission request
export { requestNotificationPermission };

// Ensure this line is at the bottom of fcm_notifications.js
window.requestNotificationPermission = requestNotificationPermission;
